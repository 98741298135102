
import { PreheaderData, PreheaderInfo } from "../../interfaces/ui"


export const preheadersInfo : PreheaderInfo[] = [
    {
        country : 'UY',
        preheaderRows : [
            {
                type : 'carousel',
                active : false ,
                preheaders : [
                    // {
                    //     urlImgDesktop : "https://cdn1.infocasas.com.uy/web/66ead71739655_oikos-preheader-desktop.png",
                    //     urlImgMobile : "https://cdn1.infocasas.com.uy/web/66ead717342ec_oikos-prehead-mobile.png",
                    //     linkToOpen : "https://www.iris.infocasas.com.uy/proyecto/597?operation=Venta",
                    //     name : 'Oikos'
                    // },
                    {
                        urlImgDesktop : "https://cdn1.infocasas.com.uy/web/674e299abc182_banner-pi_desktop.png",
                        urlImgMobile : "https://cdn1.infocasas.com.uy/web/674e299a96463_banner-pi-mobile.png",
                        linkToOpen : "https://wa.me/+59897328721?*%7CUTMS%7C*",
                        name : 'Metrics'
                    },                 
                ],
                shuffle : true
            },
            {
                type : 'single',
                active : true,
                preheaders : [
                    {
                        urlImgDesktop : "https://cdn1.infocasas.com.uy/web/674e299abc182_banner-pi_desktop.png",
                        urlImgMobile : "https://cdn1.infocasas.com.uy/web/674e299a96463_banner-pi-mobile.png",
                        linkToOpen : "https://wa.me/+59897328721?*%7CUTMS%7C*",
                        name : 'Metrics'
                    }
                ]
            }
        ]
    },
    {
        country : 'CO',
        preheaderRows : [
            {
                type : 'carousel',
                active : false,
                preheaders : [
                    
                    {
                        urlImgDesktop : "https://cdn1.infocasas.com.uy/web/67504b8b52370_proyecto_portal-iris_desktop.gif",
                        urlImgMobile : "https://cdn1.infocasas.com.uy/web/67504b8a582f8_proyecto_portal-iris_mobile.gif",
                        linkToOpen : "https://amp.fincaraiz.com.co/Iris-od-solida-4-de-diciembre?utm_source=web&utm_medium=iris_preheader",
                        name : 'OD SÓLIDA'
                    },
                    {
                        urlImgDesktop : "https://cdn1.infocasas.com.uy/web/67504b8b16638_vou_portal-iris_desktop.gif",
                        urlImgMobile : "https://cdn1.infocasas.com.uy/web/67504b8a58a86_vou_portal-iris_mobile.gif",
                        linkToOpen : "https://amp.fincaraiz.com.co/Iris-od-vou-4-de-diciembre?utm_source=web&utm_medium=iris_preheader",
                        name : 'OD VOU'
                    },
                ],
                shuffle : true
            },
            {
                type : 'single',
                active : true,
                preheaders : [
                    {
                        urlImgDesktop : "https://cdn1.infocasas.com.uy/web/675832daa4150_top-tier-hotel_desktop1.gif",
                        urlImgMobile : "https://cdn1.infocasas.com.uy/web/675832daa40ed_top-tier-hotel.gif",
                        name : 'Top Tier Hotel',
                        linkToOpen : 'https://amp.fincaraiz.com.co/Iris-od-top-tier-11-de-diciembre?utm_source=web&utm_medium=iris_preheader'
                    }
                ],
                shuffle : true
            },
            {
                type : 'single',
                preheaders : [
                    {
                        urlImgDesktop : "https://cdn1.infocasas.com.uy/web/66a7d28cbaa0d_assets_preheaderdesktop1.gif",
                        urlImgMobile : "https://cdn1.infocasas.com.uy/web/66a7d28c8c69b_assets_preheadermobile4.gif",
                        linkToOpen : "https://amp.fincaraiz.com.co/ViajeRepublicaDominicana-IRIS?utm_source=web&utm_medium=iris_preheader",
                        name : 'Viaje Republica Dominicana'
                    }
                ],
                active : false,
                shuffle : true
            },
            {
                type : 'single',
                preheaders : [
                    {
                        urlImgDesktop : "https://cdn1.infocasas.com.uy/web/67461db8cc1f7_preheaderdesktop1.gif",
                        urlImgMobile : "https://cdn1.infocasas.com.uy/web/67461db8cb87a_preheadermobile1.gif",
                        linkToOpen : "https://amp.fincaraiz.com.co//iris-webinar-terracol-26-de-noviembre?utm_source=web&utm_medium=iris_preheader",
                        name : 'Webinar Terracol'
                    }
                ],
                active : false,
                shuffle : true
            },
        ]
    },
    {
        country : 'CL',
        preheaderRows : [
            {
                type : 'single',
                active : false, //INACTIVE
                preheaders : [
                    {
                        urlImgDesktop : "https://cdn1.infocasas.com.uy/web/66956eef71be7_feed-iris-desktop.png",
                        urlImgMobile : "https://cdn1.infocasas.com.uy/web/66956eef71aaa_feed-iris-mobile.png",
                        linkToOpen : "https://www.iris.yapo.cl/proyecto/779?operation=Venta",
                        name : 'Ecasa'

                    }
                ]
            },
            {
                type : 'single',
                active : false,
                preheaders : [
                    {
                        urlImgDesktop : "https://cdn1.infocasas.com.uy/web/66c74da809df2_feed-iris-desktop-propiedades-usadas.png",
                        urlImgMobile : "https://cdn1.infocasas.com.uy/web/66c74da80a1bb_feed-iris-mobile-propiedades-usadas.png",
                        linkToOpen : '/propiedades-usadas',
                        name : 'Propiedades Usadas'
                    }
                ] 
            },
            {
                type : 'single',
                active : false,
                preheaders : [
                    {
                        urlImgDesktop : "https://cdn1.infocasas.com.uy/web/66fad9f9e94af_iris-generico-desktop.png",
                        urlImgMobile : "https://cdn1.infocasas.com.uy/web/66fad9f9be19b_iris-generico-mobile.png",
                        linkToOpen : 'https://landing.yapo.cl/iris-cyber-2024',
                        name : 'Yapo Iris cyber'
                    }
                ] 
            }
        ]
    },
    {
        country : 'PY',
        preheaderRows : [
            {
                type : 'single',
                active : true,
                preheaders : [
                    {
                        urlImgDesktop : "https://cdn1.infocasas.com.uy/web/66ad48eb63a59_ngo-iris-preheader-1142x122px.gif",
                        urlImgMobile : "https://cdn1.infocasas.com.uy/web/66ad48eb368bb_ngo-iris-preheader-427x102px.gif",
                        linkToOpen : "https://www.infocasas.com.py/ngo/amp",
                        name : 'NGO'
                    }
                ]
            }
        ]
    },
    {
        country : 'PA',
        preheaderRows: [
            {
                type: "single",
                preheaders: [
                    {
                        urlImgDesktop: "https://cdn1.infocasas.com.uy/web/66f5cc8b0f3fc_explora_portal-iris_preheaderdesktop.gif",
                        urlImgMobile: "https://cdn1.infocasas.com.uy/web/66f5cc8b0c4f6_explora_portal-iris_preheadermobile.gif",
                        linkToOpen : "https://amp.encuentra24.com/od_iris_panama_pacifico/pa?utm_source=web&utm_medium=iris_preheader",
                        endDate : '2024-10-03',
                        name : 'IrisOpenDayPA'
                    }
                ],
                active: true
            }
        ]
    }
]